import * as moment from 'moment';
import { environment } from '../../environments/environment';
var AppConstants = /** @class */ (function () {
    function AppConstants() {
    }
    Object.defineProperty(AppConstants, "maxInteger", {
        get: function () {
            return Number.MAX_SAFE_INTEGER;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "baseUrl", {
        get: function () {
            return environment.apiBaseUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "reportsUrl", {
        get: function () {
            return environment.apiReportsUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "financialUrl", {
        get: function () {
            return environment.apiFinancialUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "authUrl", {
        get: function () {
            return environment.authBaseUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "stockUrl", {
        get: function () {
            return environment.stockUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "datatablePtBr", {
        get: function () {
            return {
                'sEmptyTable': 'Nenhum resultado encontrado',
                'sInfo': 'Exibindo _START_-_END_ de _TOTAL_ resultados',
                'sInfoEmpty': 'Nenhum resultado',
                'sInfoFiltered': '(Filtrados de _MAX_ resultados)',
                'sInfoPostFix': '',
                'sInfoThousands': '.',
                'sLengthMenu': 'Exibir _MENU_ resultados',
                'sLoadingRecords': 'Carregando...',
                'sProcessing': 'Processando...',
                'sZeroRecords': 'Nenhum resultado encontrado',
                'sSearch': 'Pesquisar',
                'oPaginate': {
                    'sNext': '<span class="icon-right-open"></span>',
                    'sPrevious': '<span class="icon-left-open"></span>',
                    'sFirst': 'Primeiro',
                    'sLast': 'Último'
                },
                'oAria': {
                    'sSortAscending': ': Ordenar colunas de forma ascendente',
                    'sSortDescending': ': Ordenar colunas de forma descendente'
                }
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "masterTypes", {
        get: function () {
            return [{
                    name: 'Ticket',
                    value: 'ticket'
                }, {
                    name: 'Cashless',
                    value: 'prepaid_cashless'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "deviceTypes", {
        get: function () {
            return [{
                    name: 'Telefone',
                    value: 'phone'
                }, {
                    name: 'Lio',
                    value: 'lio'
                }, {
                    name: 'GetNet',
                    value: 'getnet'
                }, {
                    name: 'PagSeguro',
                    value: 'pagseguro'
                }, {
                    name: 'Totem',
                    value: 'totem'
                }, {
                    name: 'BeerMe',
                    value: 'beerme'
                }, {
                    name: 'Safra',
                    value: 'safra'
                }, {
                    name: 'Stone',
                    value: 'stone'
                }, {
                    name: 'Outro',
                    value: 'other'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "integrationTypes", {
        get: function () {
            return [{
                    name: 'Ingresso',
                    value: 'ticket'
                }, {
                    name: 'Ingresso e Ponto de Venda',
                    value: 'ticket_and_pos'
                }, {
                    name: 'Ponto de Venda',
                    value: 'pos'
                }, {
                    name: 'Análise de dados',
                    value: 'data_analytics'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "statusTypes", {
        get: function () {
            return [{
                    name: 'Todos',
                    value: ''
                }, {
                    name: 'Habilitado',
                    value: 'enable'
                }, {
                    name: 'Desabilitado',
                    value: 'disabled'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "permissionsTypes", {
        get: function () {
            return ['all', 'private', 'public'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "notificationsTypes", {
        get: function () {
            return ['all', 'read', 'unread'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "eventStatus", {
        get: function () {
            return [{
                    name: 'Todos',
                    value: '',
                    color: ''
                }, {
                    name: 'Pendente',
                    value: 'pending',
                    color: 'warning-color'
                }, {
                    name: 'Pronto para Operação',
                    value: 'ready',
                    color: 'success-color'
                }, {
                    name: 'Desabilitado',
                    value: 'disabled',
                    color: 'danger-color'
                }, {
                    name: 'Realizado',
                    value: 'done',
                    color: 'info-color'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "devideStatus", {
        get: function () {
            return [{
                    name: 'Todos',
                    value: ''
                }, {
                    name: 'Habilitado',
                    value: 'enabled'
                }, {
                    name: 'Desabilitado',
                    value: 'disabled'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "pendenceEntities", {
        get: function () {
            return [{
                    name: 'Todas',
                    value: ''
                }, {
                    name: 'Produto',
                    value: 'MenuItem'
                }, {
                    name: 'Técnico',
                    value: 'Technician'
                }, {
                    name: 'Ponto de venda',
                    value: 'PointOfSale'
                }, {
                    name: 'Forma de pagamento',
                    value: 'PaymentType'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "booleanTypes", {
        get: function () {
            return [{
                    name: 'Todos',
                    value: 'all'
                }, {
                    name: 'Sim',
                    value: 'true'
                }, {
                    name: 'Não',
                    value: 'false'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "paymentAcquirerTypes", {
        get: function () {
            return [{
                    name: 'Todos',
                    value: ''
                }, {
                    name: 'Crédito',
                    value: 'credit'
                }, {
                    name: 'Débito',
                    value: 'debit'
                }, {
                    name: 'Dinheiro',
                    value: 'cash'
                }, {
                    name: 'Voucher',
                    value: 'voucher'
                }, {
                    name: 'Rappi Pay',
                    value: 'rappipay'
                }, {
                    name: 'PIX',
                    value: 'pix'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "deleteNotifyConfig", {
        get: function () {
            return {
                titleMaxLength: 150,
                timeout: 0,
                showProgressBar: false,
                closeOnClick: false,
                backdrop: 0.5,
                position: 'centerCenter'
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "datetimepickerConfig", {
        get: function () {
            return {
                format: 'DD/MM/YYYY - HH:mm',
                locale: moment.locale('pt'),
                showTwentyFourHours: true,
                disableKeypress: true,
                returnedValueType: 'string',
                drops: 'down'
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "timepickerConfig", {
        get: function () {
            return {
                format: 'HH:mm',
                mode: 'time',
                locale: moment.locale('pt'),
                showTwentyFourHours: true,
                disableKeypress: true,
                returnedValueType: 'string',
                drops: 'down'
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "doughnutConfig", {
        get: function () {
            return {
                'responsive': true,
                'maintainAspectRatio': false,
                'legend': {
                    'position': 'right',
                    'labels': {
                        'boxWidth': 15
                    }
                },
                'tooltips': {
                    'callbacks': {
                        'label': function (tooltipItem, data) {
                            return data.labels[tooltipItem.index];
                        }
                    }
                }
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "barConfig", {
        get: function () {
            return {
                'responsive': true,
                'scales': {
                    'yAxes': [{
                            'ticks': {
                                'beginAtZero': true
                            }
                        }]
                },
                'tooltips': {
                    'callbacks': {
                        'label': function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel;
                        }
                    }
                }
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "featureTypes", {
        get: function () {
            return [{
                    name: 'Configurar Cardápio',
                    value: 'menu_configuration'
                }, {
                    name: 'Visualizar Operações',
                    value: 'operations_report'
                }, {
                    name: 'Relatório Caixa',
                    value: 'pos_reports'
                }, {
                    name: 'Fechar Caixa',
                    value: 'pos_closing'
                }, {
                    name: 'Cancelar Sangria',
                    value: 'withdrawal_cancellation'
                }, {
                    name: 'Sangria',
                    value: 'withdrawal'
                }, {
                    name: 'Cancelar Estorno',
                    value: 'refund_cancellation'
                }, {
                    name: 'Estorno',
                    value: 'refund'
                }, {
                    name: 'Cancelar Venda',
                    value: 'ticket_sale_cancellation'
                }, {
                    name: 'Reimprimir',
                    value: 'reprint'
                }, {
                    name: 'Abrir Evento',
                    value: 'open_event'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "operationFilterTypes", {
        get: function () {
            return [{
                    name: 'Todas',
                    value: ''
                }, {
                    name: 'Vendas',
                    value: 'ticket_sale'
                }, {
                    name: 'Vendas Canceladas',
                    value: 'ticket_refund'
                }, {
                    name: 'Estornos',
                    value: 'refund'
                }, {
                    name: 'Estornos Cancelados',
                    value: 'refund_canceled'
                }, {
                    name: 'Vendas Re-impressas',
                    value: 'reprint'
                }, {
                    name: 'Sangrias',
                    value: 'withdrawal'
                }, {
                    name: 'Sangrias Canceladas',
                    value: 'withdrawal_canceled'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "operationTypes", {
        get: function () {
            return [{
                    name: 'Venda',
                    value: 'ticket_sale'
                }, {
                    name: 'Estorno',
                    value: 'ticket_refund'
                }, {
                    name: 'Sangria',
                    value: 'withdrawal'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "cashlessOperationTypes", {
        get: function () {
            return [{
                    name: 'Recarga',
                    value: 'recharge'
                }, {
                    name: 'Consumo',
                    value: 'consumption'
                }, {
                    name: 'Ativação',
                    value: 'card_activation'
                }, {
                    name: 'Devolução',
                    value: 'card_return'
                }, {
                    name: 'Bloqueio',
                    value: 'card_block'
                }, {
                    name: 'Estorno de consumo',
                    value: 'consumption_refund'
                }, {
                    name: 'Estorno de recarga',
                    value: 'recharge_refund'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "clientLegalTypes", {
        get: function () {
            return [{
                    name: 'Pessoa Física',
                    value: 'physical_person'
                }, {
                    name: 'Pessoa Jurídica',
                    value: 'legal_person'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "occurrenceTypes", {
        get: function () {
            return [{
                    name: 'Cancelamento',
                    value: 'cancellation'
                }, {
                    name: 'Reimpressão',
                    value: 'reprint'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "paymentStatus", {
        get: function () {
            return [{
                    name: 'Autorizado',
                    value: 'authorized'
                }, {
                    name: 'Cancelado',
                    value: 'cancelled'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "cashierTypes", {
        get: function () {
            return [{
                    name: 'Todos os caixas',
                    value: ''
                }, {
                    name: 'Somente produção',
                    value: true
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "eventTypes", {
        get: function () {
            return [{
                    name: 'Ticket',
                    value: 'ticket'
                }, {
                    name: 'Cashless',
                    value: 'prepaid_cashless'
                }, {
                    name: 'Bilheteria',
                    value: 'ticket_office'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "operationCashlessFilterTypes", {
        get: function () {
            return [{
                    name: 'Todos',
                    value: ''
                }, {
                    name: 'Recargas',
                    value: 'recharge'
                }, {
                    name: 'Recargas Canceladas',
                    value: 'recharge_canceled'
                }, {
                    name: 'Estorno de Recargas',
                    value: 'recharge_refund'
                }, {
                    name: 'Estorno de Recargas Canceladas',
                    value: 'recharge_refund_canceled'
                }, {
                    name: 'Consumos',
                    value: 'consumption'
                }, {
                    name: 'Consumos Cancelados',
                    value: 'consumption_canceled'
                }, {
                    name: 'Estorno de Consumo',
                    value: 'consumption_refund'
                }, {
                    name: 'Estorno de Consumo Cancelado',
                    value: 'consumption_refund_canceled'
                }, {
                    name: 'Ativações',
                    value: 'card_activation'
                }, {
                    name: 'Devoluções',
                    value: 'card_return'
                }, {
                    name: 'Sangrias',
                    value: 'withdrawal'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "operationCashlessPospaidFilterTypes", {
        get: function () {
            return [{
                    name: 'Todos',
                    value: ''
                }, {
                    name: 'Pagamento',
                    value: 'card_payment'
                }, {
                    name: 'Recarga',
                    value: 'recharge'
                }, {
                    name: 'Recargas Canceladas',
                    value: 'recharge_canceled'
                }, {
                    name: 'Estorno de Recargas',
                    value: 'recharge_refund'
                }, {
                    name: 'Estorno de Recargas Canceladas',
                    value: 'recharge_refund_canceled'
                }, {
                    name: 'Consumos',
                    value: 'consumption'
                }, {
                    name: 'Consumos Cancelados',
                    value: 'consumption_canceled'
                }, {
                    name: 'Estorno de Consumo',
                    value: 'consumption_refund'
                }, {
                    name: 'Estorno de Consumo Cancelado',
                    value: 'consumption_refund_canceled'
                }, {
                    name: 'Ativações',
                    value: 'card_activation'
                }, {
                    name: 'Devoluções',
                    value: 'card_return'
                }, {
                    name: 'Sangrias',
                    value: 'withdrawal'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "paymentStatuses", {
        get: function () {
            return [{
                    name: 'Autorizado',
                    value: 'authorized'
                }, {
                    name: 'Cancelado',
                    value: 'cancelled'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "customInfoTypes", {
        get: function () {
            return [{
                    label: 'Número',
                    name: 'number',
                    value: '0'
                }, {
                    label: 'Texto',
                    name: 'text',
                    value: '1'
                }, {
                    label: 'CPF',
                    name: 'cpf',
                    value: '2'
                }, {
                    label: 'CNPJ',
                    name: 'cnpj',
                    value: '3'
                }, {
                    label: 'Telefone',
                    name: 'telephone',
                    value: '4'
                }, {
                    label: 'Código de Barras/QRCode',
                    name: 'barcode',
                    value: '5'
                }, {
                    label: 'Ingresso',
                    name: 'ticket',
                    value: '6'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "streetPrefixes", {
        get: function () {
            return [{
                    name: 'Rua',
                    value: 'street'
                }, {
                    name: 'Avenida',
                    value: 'avenue'
                }, {
                    name: 'Alameda',
                    value: 'lane'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "amountTypes", {
        get: function () {
            return [
                {
                    id: 'fraction',
                    name: 'Fracionado'
                }, {
                    id: 'package',
                    name: 'Inteiro'
                }
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "brazilStates", {
        get: function () {
            return [{
                    name: 'Acre',
                    value: 'AC'
                }, {
                    name: 'Alagoas',
                    value: 'AL'
                }, {
                    name: 'Amapá',
                    value: 'AP'
                }, {
                    name: 'Amazonas',
                    value: 'AM'
                }, {
                    name: 'Bahia',
                    value: 'BA'
                }, {
                    name: 'Ceará',
                    value: 'CE'
                }, {
                    name: 'Distrito Federal',
                    value: 'DF'
                }, {
                    name: 'Espírito Santo',
                    value: 'ES'
                }, {
                    name: 'Goiás',
                    value: 'GO'
                }, {
                    name: 'Maranhão',
                    value: 'MA'
                }, {
                    name: 'Mato Grosso',
                    value: 'MT'
                }, {
                    name: 'Mato Grosso do Sul',
                    value: 'MS'
                }, {
                    name: 'Minas Gerais',
                    value: 'MG'
                }, {
                    name: 'Pará',
                    value: 'PA'
                }, {
                    name: 'Paraíba',
                    value: 'PB'
                }, {
                    name: 'Paraná',
                    value: 'PR'
                }, {
                    name: 'Pernambuco',
                    value: 'PE'
                }, {
                    name: 'Piauí',
                    value: 'PI'
                }, {
                    name: 'Rio de Janeiro',
                    value: 'RJ'
                }, {
                    name: 'Rio Grande do Norte',
                    value: 'RN'
                }, {
                    name: 'Rio Grande do Sul',
                    value: 'RS'
                }, {
                    name: 'Rondônia',
                    value: 'RO'
                }, {
                    name: 'Roraima',
                    value: 'RR'
                }, {
                    name: 'Santa Catarina',
                    value: 'SC'
                }, {
                    name: 'São Paulo',
                    value: 'SP'
                }, {
                    name: 'Sergipe',
                    value: 'SE'
                }, {
                    name: 'Tocantins',
                    value: 'TO'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "printModes", {
        get: function () {
            return [{
                    name: 'Ficha Padrão',
                    value: 'normal'
                }, {
                    name: 'Ficha Agrupada',
                    value: 'grouped'
                }, {
                    name: 'Ficha Agrupada com Senha',
                    value: 'grouped_with_pass'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "unitMeasures", {
        get: function () {
            return [{
                    value: 'kilo',
                    label: 'Quilo (kg)'
                }, {
                    value: 'liter',
                    label: 'Litro (l)'
                }, {
                    value: 'gramme',
                    label: 'Grama (g)'
                }, {
                    value: 'milliliter',
                    label: 'Mililitro (l)'
                }, {
                    value: 'unit',
                    label: 'Unidade (un)'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "productStatusFiscal", {
        get: function () {
            return [{
                    value: 'none',
                    label: 'Não possui informações fiscais',
                    color: 'dark-gray-color'
                }, {
                    value: 'pending',
                    label: 'Cadastro fiscal pendente',
                    color: 'warning-color'
                }, {
                    value: 'success',
                    label: 'Informações fiscais válidas',
                    color: 'success-color'
                }, {
                    value: 'failure',
                    label: 'Erro ao cadastrar informações fiscais',
                    color: 'danger-color'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "invoiceModes", {
        get: function () {
            return [{
                    label: 'Assíncrono',
                    value: 'async'
                }, {
                    label: 'Síncrono',
                    value: 'sync'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "invoiceTypes", {
        get: function () {
            return [{
                    label: 'Automático',
                    value: 'automatic'
                }, {
                    label: 'NFC-e',
                    value: 'nfce'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "invoiceEnvironments", {
        get: function () {
            return [{
                    label: 'Homologação',
                    value: 'staging'
                }, {
                    label: 'Produção',
                    value: 'production'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "invoiceStatus", {
        get: function () {
            return [{
                    name: 'Autorizada',
                    value: 'authorized',
                    color: 'success-color'
                }, {
                    name: 'Cancelada',
                    value: 'cancelled',
                    color: 'danger-color'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "eventIntegrationProviders", {
        get: function () {
            return [{
                    label: 'Sympla',
                    name: 'sympla',
                    value: '0'
                }, {
                    label: 'BeerMe',
                    name: 'beerme',
                    value: '1'
                }, {
                    label: 'Outro',
                    name: 'other',
                    value: '2'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "validationStatuses", {
        get: function () {
            return [{
                    name: 'Todos',
                    value: '',
                    color: ''
                }, {
                    name: 'Sucesso',
                    value: true,
                    color: 'success-color'
                }, {
                    name: 'Falha',
                    value: false,
                    color: 'danger-color'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "eventIntegrationStatus", {
        get: function () {
            return [{
                    name: 'Pendente',
                    value: 'pending',
                    color: 'warning-color'
                }, {
                    name: 'Processando',
                    value: 'running',
                    color: 'success-color'
                }, {
                    name: 'Finalizada',
                    value: 'completed',
                    color: 'info-color'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "eventIntegrationAttendeeStatus", {
        get: function () {
            return [{
                    name: 'Automático',
                    value: 'automatic',
                    color: 'success-color'
                }, {
                    name: 'Sincronizando',
                    value: 'synchronizing',
                    color: 'warning-color'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "closureStatus", {
        get: function () {
            return [{
                    name: 'Consolidado',
                    value: 'consolidated'
                }, {
                    name: 'Recalculando',
                    value: 'consolidating'
                }, {
                    name: 'Divergente',
                    value: 'consolidated_with_problems'
                }, {
                    name: 'Aprovado',
                    value: 'approved'
                }, {
                    name: 'Aguardando Análise',
                    value: 'awaiting_analysis'
                }, {
                    name: "Bloqueado",
                    value: "blocked"
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "creditAdjustOperationsStatus", {
        get: function () {
            return [{
                    name: 'Aprovada',
                    value: 'approved'
                }, {
                    name: 'Reprovada',
                    value: 'rejected'
                }, {
                    name: 'Aguardando',
                    value: 'waiting'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "campaignMode", {
        get: function () {
            return [{
                    name: 'Online',
                    value: 'online'
                }, {
                    name: 'Offline',
                    value: 'offline'
                }];
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(AppConstants, "discountType", {
        get: function () {
            return [{
                    name: "Cupom de Desconto",
                    value: "discount"
                }, {
                    name: "Produto Extra",
                    value: "extra_products"
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "discountSubtype", {
        get: function () {
            return [{
                    name: "Porcentagem",
                    value: "percentage"
                }, {
                    name: "Valor Absoluto",
                    value: "absolute"
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "triggerTypes", {
        get: function () {
            return [{
                    name: "Cupom",
                    value: "simple_code"
                }, {
                    name: "Voucher",
                    value: "voucher_code"
                }, {
                    name: "Cartão de crédito",
                    value: "card_code"
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "benefitsCampaignPeriodsTypes", {
        get: function () {
            return [{
                    name: "Mesmo Período do Evento",
                    value: "event_period"
                }, {
                    name: "Período Fixo",
                    value: "fixed_period"
                }, {
                    name: "Recorrente",
                    value: "recurrence"
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "PeriodsRecurrenceTypes", {
        get: function () {
            return [{
                    name: "Mensalmente",
                    value: "monthly"
                }, {
                    name: "Semanalmente",
                    value: "weekly"
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "PeriodsRecurrenceWeekDays", {
        get: function () {
            return [{
                    value: 'Sunday',
                    name: 'Domingo'
                }, {
                    value: 'Monday',
                    name: 'Segunda-feira'
                }, {
                    value: 'Tuesday',
                    name: 'Terça-feira'
                }, {
                    value: 'Wednesday',
                    name: 'Quarta-feira'
                }, {
                    value: 'Thursday',
                    name: 'Quinta-feira'
                }, {
                    value: 'Friday',
                    name: 'Sexta-feira'
                }, {
                    value: 'Saturday',
                    name: 'Sábado'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "PeriodsRecurrenceMonthDays", {
        get: function () {
            var daysOfMonth = Array.from({ length: 31 }, function (v, i) { return ({
                value: i + 1,
                name: "Dia " + (i + 1)
            }); });
            return daysOfMonth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "transactionTypes", {
        get: function () {
            return [{
                    name: 'Repasse',
                    value: 'balance_withdrawal'
                }, {
                    name: 'Adiantamento',
                    value: 'advance'
                }, {
                    name: 'Registro de Saldo',
                    value: 'balance_entry'
                }, {
                    name: 'Ajuste de Crédito',
                    value: 'credit_adjust'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "advancesManagementStatusList", {
        get: function () {
            return [
                {
                    name: 'Solicitado',
                    value: 'requested'
                }, {
                    name: 'Recusado',
                    value: 'refused'
                }, {
                    name: 'Processando',
                    value: 'processing'
                }, {
                    name: 'Realizado',
                    value: 'processed'
                }
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "transferManagementFormatList", {
        get: function () {
            return [
                {
                    name: 'Conta Própria',
                    value: 'own_account'
                }, {
                    name: 'Conta de Terceiros',
                    value: 'third_party_account'
                }, {
                    name: 'Boleto bancário',
                    value: 'charge_payment'
                }
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "nfeStatus", {
        get: function () {
            return [{
                    name: 'Autorizada',
                    value: 'authorized'
                }, {
                    name: 'Cancelada',
                    value: 'cancelled'
                }, {
                    name: 'Pendente',
                    value: 'pending'
                }, {
                    name: 'Não Autorizada',
                    value: 'unauthorized'
                }];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConstants, "errorMessages", {
        get: function () {
            return {
                400: "Requisição inválida",
                401: "Não autorizado",
                403: "Proibido",
                404: "Evento não encontrado",
                405: "Método não permitido",
                500: "Erro interno do servidor",
                502: "Bad Gateway",
                503: "Serviço temporariamente indisponível",
                504: "Gateway timeout"
            };
        },
        enumerable: true,
        configurable: true
    });
    return AppConstants;
}());
export { AppConstants };
